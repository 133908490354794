import axios from "axios";
const ENDPOINT = "/api/v1/locations";

const get = async payload => {

  return axios.get(ENDPOINT, { params: payload });
};


const getParent = async payload => {

  return axios.get(ENDPOINT + '/parent', { params: payload });
};

const getByChildren = async payload => {

  return axios.get(ENDPOINT + '/parent-children', { params: payload });
};
const schools = async payload => {
  return axios.get(ENDPOINT + '/schools', { params: payload });
};

const create = async payload => {
  return await axios.post(ENDPOINT, payload);
};

const update = async payload => {
  return await axios.put(ENDPOINT, payload);
};

const remove = async payload => {
  return axios.delete(ENDPOINT + "/" + payload);
};

export { get, schools, getByChildren, create, update, remove, getParent };
